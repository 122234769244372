<template>
  <div class="card">
    <div
      v-b-toggle="`collapseImageDiv`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Images</a> <span class="badge bg-light-success">{{ processedImg ? processedImg.length : 0 }}</span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseImageDiv`"
      class="mt-3"
    >
      <div class="card-content">
        <div class="card-body">
          <div class="row row-cols-1 row-cols-md-4 row-cols-sm-3 mb-2 row__list-of-images">
            <div
              v-for="image, index in processedImg"
              :key="image.name"
              class="col"
            >
              <div class="card h-100">
                <!-- <div
                  class="card-img-top"
                  :style="{'background-image':`url('${image.url}')`}"
                /> -->
                <img
                  :src="image.url"
                  class="img-fluid"
                >
                <div class="card-body d-flex justify-content-between">
                  <a
                    class="btn btn-icon btn-flat-danger"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Delete image"
                    @click="removeImage(image.id, index)"
                  ><i data-feather="trash-2" /></a>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="image.principal"
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      value="true"
                    >
                    <label
                      class="form-check-label"
                      for="principalImage2"
                    >Principal</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <a
            v-b-toggle="`collapseImage`"
            class="btn btn-sm btn-dark dis-ib"
          ><i data-feather="plus" /> Add image</a>

          <b-collapse
            :id="`collapseImage`"
            class="mt-3"
          >
            <div class="card shadow-none bg-transparent border-secondary">
              <div class="card-body">
                <h3>Add image</h3>
                <div class="mb-1">
                  <label
                    for="formImage"
                    class="form-label"
                  >{{ labels['add_image.select_image'] }}</label>
                  <input
                    id="formImage"
                    :ref="`image`"
                    class="form-control"
                    type="file"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >{{ labels['add_image.description'] }}</label>
                  <input
                    v-model="description"
                    type="text"
                    class="form-control"
                  >
                </div>
                <div class="mb-2">
                  <a
                    class="btn btn-sm btn-outline-success me-1"
                    @click="uploadFile"
                  >Upload image</a>
                  <a
                    class="btn btn-sm btn-flat-danger"
                    data-bs-toggle="collapse"
                    href="#collapseAddImage"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseAddImage"
                  >Cancel</a>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    title: { type: String, required: true },
    images: { type: Array, required: false, default: null },
  },
  data() {
    return {
      processedImg: [],
      newImages: [],
      description: '',
      labels: [],
    }
  },
  watch: {
    images() {
      this.processedImg = this.images != null ? this.images : []
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.images`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
    this.processedImg = this.images != null ? this.images : []
  },
  methods: {
    uploadFile() {
      const file = this.$refs.image.files[0]

      this.processedImg.push({
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        file,
        name: file.name,
        description: this.description,
        url: URL.createObjectURL(file),
      })

      this.newImages.push({
        weight: (file.size / 1024 / 1024).toFixed(2),
        extension: file.type,
        created_at: 'Pending to save...',
        file,
        name: file.name,
        description: this.description,
        url: URL.createObjectURL(file),
      })

      this.saveImages()
    },
    saveImages() {
      feather.replace({
        width: 14,
        height: 14,
      })
      this.$emit('saveAnswer', { id: 'images', data: this.newImages })
    },
    removeImage(fileId, index) {
      if (fileId) {
        this.$store.dispatch('lines/deleteFile', fileId)
      }

      this.processedImg.splice(index, 1)
      this.newImages.splice(index, 1)
      this.$emit('saveAnswer', { id: 'images', data: this.processedImg })
      Vue.swal('Image deleted successfully!', '', 'success')
    },
  },
}
</script>
