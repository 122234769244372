<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseContact-${id}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Contacts</a> <span class="badge bg-light-success">{{ contacts ? contacts.length : 0 }}</span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseContact-${id}`"
      >
        <div class="table-responsive-sm">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Department</th>
                <th>Phone</th>
                <th>Gender</th>
                <th>Mail</th>
                <th>Note</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="contact, index in contacts"
                :key="`c-${index}`"
              >
                <td><strong>{{ contact.full_name }}</strong></td>
                <td>{{ contact.position ? contact.position.name : '-' }}</td>
                <td>{{ contact.department ? contact.department.name : '-' }}</td>
                <td>{{ contact.phone }}</td>
                <td>{{ contact.genre ? contact.genre.name : '-' }}</td>
                <td>{{ contact.email }}</td>
                <td v-html="contact.notes" />
                <td class="text-end">
                  <b-dropdown
                    v-if="contact.id"
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="showContact(contact)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View contact information
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="contact.id"
                        @click="$router.push({name: 'admin.institution-contacts.edit', params: { id: contact.id }})"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="showDeleteModal(contact.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <span
                    v-else
                    class="dis-ib"
                    title="Delete area & group"
                    @click="contacts.splice(index, 1)"
                  ><a
                    href="#"
                    class="btn btn-icon rounded-circle btn-flat-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                  ><i data-feather="trash-2" /></a></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-body">
          <a
            v-b-toggle="`collapseConcact-0`"
            class="btn btn-sm btn-dark dis-ib"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="collapseAddConcact"
          ><i data-feather="plus" /> Add contact</a>
          <b-collapse
            :id="`collapseConcact-0`"
            class="collapse mt-3"
          >
            <div
              class="card shadow-none bg-transparent border-secondary"
            >
              <div class="card-body">
                <h3>Add contact</h3>
                <div class="row mb-1">
                  <div class="col-sm-3">
                    <label
                      for=""
                      class="form-label"
                    >First name</label>
                    <input
                      v-model="contactForm.first_name"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-3">
                    <label
                      for=""
                      class="form-label"
                    >Last name</label>
                    <input
                      v-model="contactForm.last_name"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-3">
                    <label
                      for=""
                      class="form-label"
                    >Position</label>
                    <v-select
                      v-model="contactForm.position"
                      label="name"
                      :clearable="false"
                      :options="positions"
                      :get-option-key="option => option.id"
                      @search="onSearch({ name: $event }, 'institutions/fetchPositions')"
                    />
                  </div>
                  <div class="col-sm-3">
                    <label
                      for=""
                      class="form-label"
                    >Department</label>
                    <v-select
                      v-model="contactForm.department"
                      label="name"
                      :options="departments.concat(departmentsInsti)"
                      :get-option-key="option => option.id"
                    />
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-sm-3">
                    <label
                      for=""
                      class="form-label"
                    >Phone</label>
                    <input
                      v-model="contactForm.phone"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-3">
                    <label
                      for=""
                      class="form-label"
                    >Mobile phone</label>
                    <input
                      v-model="contactForm.mobile_phone"
                      type="text"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-3">
                    <label
                      for=""
                      class="form-label"
                    >Email</label>
                    <input
                      v-model="contactForm.email"
                      type="mail"
                      class="form-control"
                    >
                  </div>
                  <div class="col-sm-3">
                    <div class="row">
                      <div class="col-6">
                        <label
                          for=""
                          class="form-label"
                        >Status</label>
                        <select
                          id=""
                          v-model="contactForm.status"
                          name=""
                          class="form-select"
                        >
                          <option value="1">
                            Active
                          </option>
                          <option value="0">
                            Arxived
                          </option>
                        </select>
                      </div>
                      <div class="col-6">
                        <label
                          for=""
                          class="form-label"
                        >Gender</label>
                        <v-select
                          v-model="contactForm.genre"
                          label="name"
                          :options="genders"
                          :get-option-key="option => option.id"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col">
                    <label
                      for=""
                      class="form-label"
                    >Notes</label>
                    <quill-editor v-model="contactForm.notes" />
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-sm-6">
                    <div class="col-md-4 align-items-center form-switch d-flex ps-0">
                      <div class="mb-1">
                        <label
                          class="form-check-label"
                          for="publishContent1"
                        ><small>Mark as Evaluator host</small></label>
                        <input
                          id="publishContent1"
                          v-model="contactForm.evaluatorHost"
                          type="checkbox"
                          class="form-check-input ms-2"
                          value="1"
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-2">
                  <a
                    v-b-toggle="`collapseConcact-0`"
                    class="btn btn-sm btn-outline-success me-1"
                    @click="uploadContact"
                  >Upload contact</a>
                  <a
                    v-b-toggle="`collapseConcact-0`"
                    class="btn btn-sm btn-flat-danger"
                  >Cancel</a>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </b-collapse>
    </div>
    <ItemInfoSheet v-if="selectedContact">
      <template #info-sheet-title>
        View contact information
      </template>
      <template #info-sheet>
        <div class="offcanvas-body offcanvas-body--view">
          <div class="mb-1 offcanvas-body--view--title">
            <p>Name<br>
              <strong>{{ selectedContact.first_name }} {{ selectedContact.last_name }}</strong></p>
          </div>
          <div class="mb-1">
            <p>Status<br>
              <strong
                v-if="selectedContact.status"
                class="badge badge-light-success"
              >Active</strong>
              <strong
                v-if="!selectedContact.status"
                class="badge badge-light-danger"
              >Archived</strong>
            </p>
          </div>
          <hr>
          <div class="mb-1">
            <p>Position<br>
              <strong>{{ selectedContact.position ? selectedContact.position.name : '-' }}</strong></p>
          </div>
          <div class="mb-1">
            <p>Hosts institution<br>
              <strong>{{ selectedContact.host ? selectedContact.host.name : '-' }}</strong></p>
          </div>
          <div class="mb-1">
            <p>Department<br>
              <strong>{{ selectedContact.department ? selectedContact.department.name : '-' }}</strong></p>
          </div>
          <div class="mb-1">
            <p>Phone<br>
              <strong>{{ selectedContact.phone }}</strong></p>
          </div>
          <div class="mb-1">
            <p>Mobile phone<br>
              <strong>{{ selectedContact.mobile_phone }}</strong></p>
          </div>
          <div class="mb-1">
            <p>Email<br>
              <strong>{{ selectedContact.email }}</strong></p>
          </div>

        </div>
        <div class="offcanvas-footer mt-auto">
          <a
            v-if="selectedContact.id"
            title="edit this contact"
            class="btn btn-primary mb-1 d-grid w-100"
            @click="$router.push({name: 'admin.institution-contacts.edit', params: { id: selectedContact.id }})"
          >Edit</a>
        </div>
      </template>
    </ItemInfoSheet>
  </div>
</template>

<script>
import Vue from 'vue'
import { BDropdown, BDropdownItem, BCollapse } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'

export default {
  components: {
    BCollapse,
    BDropdown,
    BDropdownItem,
    vSelect,
    ItemInfoSheet,
  },
  props: {
    contacts: { type: Array, required: false, default: () => [] },
    departmentsInsti: { type: Array, required: false, default: () => [] },
    id: { type: Number, required: true },
  },
  data() {
    return {
      contactForm: {},
      selectedContact: null,
    }
  },
  computed: {
    ...mapGetters({
      departments: 'institutions/departments',
      positions: 'institutions/positions',
      genders: 'genres/items',
    }),
  },
  mounted() {
    this.$store.dispatch('genres/fetch')
  },
  methods: {
    uploadContact() {
      this.contactForm.full_name = this.contactForm.first_name
      if (this.contactForm.evaluatorHost) {
        this.contactForm.evaluator = 'aux'
      }
      if (this.contactForm.department) {
        this.contactForm.department = {
          name: this.contactForm.department.name,
          id: this.contactForm.department.id,
        }
      }

      this.contacts.push(this.contactForm)
      this.saveFiles()
      this.contactForm = {}
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
    saveFiles() {
      this.$emit('saveAnswer', this.contacts)
    },
    deleteContact(index) {
      this.contacts.splice(index, 1)
      Vue.swal('Contact deleted successfully!', '', 'success')
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    showContact(contact) {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.selectedContact = contact
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'institutions/fetchInstitution', url: `${Vue.prototype.$groupUrl}/institutions/contacts/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
