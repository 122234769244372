<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseAlias-${id}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ title }}</a> <span class="badge bg-light-success">{{ al ? al.length : 0 }}</span>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a
              data-action="collapse"
            ><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <b-collapse
        :id="`collapseAlias-${id}`"
        :visible="visible"
      >
        <div
          class="
            card-header
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <div>
            <h4><strong>{{ al ? al.length : 0 }}</strong> alias</h4>
          </div>
        </div>
        <div class="table-responsive-sm">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="als, index in al"
                :key="als.id"
              >
                <td>{{ als.name }}</td>
                <td class="text-end">
                  <a
                    class="
                      btn btn-icon btn-icon
                      rounded-circle
                      btn-flat-danger
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#modalDeleteWithInput"
                    @click="deleteAlias(index)"
                  ><i data-feather="trash-2" /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-body">
          <a
            v-b-toggle="`collapseAddAlias`"
            class="btn btn-sm btn-dark dis-ib"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="collapseAddFile"
          ><i data-feather="plus" /> Add alias</a>
          <b-collapse
            :id="`collapseAddAlias`"
            class="collapse mt-3"
          >
            <div
              class="card shadow-none bg-transparent border-secondary"
            >
              <div class="card-body">
                <h3>Add alias</h3>
                <div class="row">
                  <div :class="'col-sm-12'">
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >{{ labels['name'] }}</label>
                      <input
                        v-model="name"
                        type="text"
                        class="form-control"
                      >
                    </div>
                  </div>
                </div>
                <div class="mb-2">
                  <a
                    class="btn btn-sm btn-outline-success me-1"
                    @click="save"
                  >Save contact</a>
                  <a
                    v-b-toggle="`collapseAddAlias`"
                    class="btn btn-sm btn-flat-danger"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseAddFile"
                  >Cancel</a>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    title: { type: String, required: true },
    id: { type: Number, required: true },
    visible: { type: Boolean, required: false, default: false },
    alias: { type: Array, required: false, default: null },
  },
  data() {
    return {
      al: [],
      name: null,
      labels: [],
    }
  },
  watch: {
    alias() {
      this.al = this.alias
    },
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.alias`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
    this.al = this.alias ?? []
  },
  methods: {
    save() {
      if (this.name) {
        this.al.push({ name: this.name })
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      }

      this.$emit('saveAnswer', this.al)
    },
    deleteAlias(index) {
      this.al.splice(index, 1)
      Vue.swal('Alias deleted successfully!', '', 'success')
    },
  },
}
</script>
