<template>
  <ItemInfoSheet :table-id="filterInfoSheet">
    <template #info-sheet-title>
      Transfer Users
    </template>
    <template
      #info-sheet
    >
      <div class="offcanvas-body">

        <h3 class="mb-1">
          {{ department.name }}
        </h3>
        <h4 class="mb-2">
          {{ usersSelected.length }} users selected
        </h4>

        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Department</label>

          <v-select
            v-model="departmentSelected"
            label="name"
            :options="departments.filter(e => e.id !== department.id)"
            :get-option-key="option => option.id"
          />
        </div>

        <hr>

        <table
          v-if="users"
          class="table"
        >
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  :checked="users.length && usersSelected.length === users.length"
                  @click="selectAll($event.target.checked)"
                >
              </th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in users"
              :key="user.id"
            >
              <td>
                <input
                  type="checkbox"
                  :checked="usersSelected.includes(user.id)"
                  @click="select($event.target.checked, user.id)"
                >
              </td>
              <td>{{ user.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-dark mb-1 d-grid w-100"
          @click="applyFilters"
        >
          Apply
        </button>
      </div>
    </template>
  </ItemInfoSheet>
</template>

<script>
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    ItemInfoSheet,
    vSelect,
  },
  props: {
    departmentId: { type: Number, required: false, default: undefined },
    departments: { type: Array, required: false, default: undefined },
  },
  data() {
    return {
      filterInfoSheet: 1,
      department: {},
      usersSelected: [],
      departmentSelected: null,
    }
  },
  computed: {
    ...mapGetters({
      infoSheet: 'modals/infoSheet',
      users: 'institutions/users',
    }),
  },
  watch: {
    async departmentId(newDepartmentId) {
      if (newDepartmentId) {
        this.showTransferUsers()
        await this.$store.dispatch('institutions/fetchUsersByDepartment', this.departmentId)
      } else this.hideTransferUsers()
    },
    infoSheet(newState) {
      if (!newState) {
        this.clearItems()
        this.$emit('removeDepartmentId')
      }
    },
  },
  methods: {
    clearItems() {
      this.department = {}
      this.departmentSelected = null
      this.usersSelected = []
    },
    showTransferUsers() {
      this.clearItems()
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)
      const departmentToTransfer = this.departments.filter(e => e.id === this.departmentId)
      this.department = departmentToTransfer.length ? departmentToTransfer[0] : {}
    },
    hideTransferUsers() {
      this.clearItems()
      this.$emit('removeDepartmentId')
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
      this.$store.dispatch('modals/toggleTableClicked', this.filterInfoSheet)
    },
    async applyFilters() {
      if (!this.usersSelected || !this.departmentSelected.id) {
        return
      }

      Vue.swal({
        title: 'Perform the following actions on the selected department users?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          let status = ''

          try {
            const resp = await this.$store.dispatch('institutions/transferUsers', { data: { users: this.usersSelected, newDepartment: this.departmentSelected.id }, departmentId: this.departmentId })
            status = resp
          } catch (e) {
            console.log(e)
          }

          if (status === 'OK') {
            Vue.swal('Users transferred successfully!', '', 'success')
          } else {
            Vue.swal('Something went wrong!', '', 'error')
          }

          this.hideTransferUsers()
        }
      })
    },
    selectAll(data) {
      this.usersSelected = []
      if (data) {
        this.usersSelected = this.users.map(e => e.id)
      }
    },
    select(data, userId) {
      if (data) this.usersSelected.push(userId)
      else {
        const index = this.usersSelected.indexOf(userId)
        if (index !== -1) this.usersSelected.splice(index, 1)
      }
    },
  },
}
</script>

<style>

</style>
